import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store';
import actions from './actions';

Vue.use(VueRouter);

const routes = [
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

const decodeObject = (object) => Object.entries(object).reduce((acc, cur) => ({
  ...acc,
  [decodeURIComponent(cur[0])]: decodeURIComponent(cur[1]),
}), {});

router.beforeEach(async (to, from, next) => {
  // const reqs = [
  //   ...new Set(
  //     to.matched.reduce((acc, cv) => (acc.concat(cv.meta.requires || [])), []),
  //   ),
  // ];
  if (to.query.s) {
    await store.dispatch(actions.fireKey('init', to.query.s, decodeObject(to.query)));
    router.push(to.path);
    return next();
  }
  const state = JSON.parse(localStorage.getItem('yabbr-wizard-store') || '{}');
  if (to.query.state && (!state.workflow || !state.workflow.ignoreStateRedirect)) {
    const parts = to.query.state.split('_');

    await store.dispatch(actions.fireKey('init', parts[1], decodeObject(to.query)));
    router.push(to.path);
    return next();
  }

  if (state.redirectStep) {
    await store.dispatch(actions.fireKey('init', state.redirectStep, decodeObject(to.query)));
    router.push(to.path);
    return next();
  }

  if (!store.getState().context) {
    try {
      await store.dispatch(actions.appInit());
    } catch (e) {
      console.log(e);
      store.dispatch(actions.endSession());
    }
  }

  return next();
});

export default router;
